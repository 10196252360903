(function ($) {
  $.modal = function (options) {
    var settings = $.extend({
      parent: $('body')
    }, options);
    var dialog = $('<div class="modal"><div class="modalClose modalCloseCorner"><div class="modalCloseLetter">&times;</div></div></div>');
    if (settings.id) {
      dialog.prop('id', settings.id);
    }
    if (settings.modalClass) {
      dialog.addClass(settings.modalClass);
    }
    if (settings.title) {
      var title = settings.title.content ? $('<div class="modalTitle"></div>').html(settings.title.content)
        : $('<div class="modalTitle"></div>').text(settings.title.text);
      if (settings.title.titleClass) {
        title.addClass(settings.title.titleClass);
      } else {
        title.addClass('modalGenericTitle');
        title.addClass(settings.modalClass + 'Title');
      }

      dialog.append(title);
    } else {
      dialog.addClass('modalNoTitle');
    }
    if (settings.content) {
      dialog.append(settings.content);
    }
    if (settings.buttons) {
      var modalButtonsArea = $('<div class="modalButtonsArea"></div>');
      $.each(settings.buttons, function (i, button) {
        var buttonEl = $('<div class="modalErrorButton"></div>');
        if (button.title) {
          buttonEl.text(button.title);
        }
        if (button.html) {
          buttonEl.html(button.html);
        }
        if (button.handler) {
          buttonEl.on('click', button.data, button.handler);
        } else {
          if (!button.noClose) {
            buttonEl.addClass('modalClose');
          }
        }

        if (button.buttonClass) {
          buttonEl.addClass(button.buttonClass);
        }

        if (button.id) {
          buttonEl.prop('id', button.id);
        }

        if (button.data) {
          $.each(button.data, function (i, data) {
            buttonEl.data(data.key, data.value);
          });
        }

        modalButtonsArea.append(buttonEl);
      });
      dialog.append(modalButtonsArea);
    }

    if (settings.contentAfter) {
      dialog.append(settings.contentAfter);
    }

    if (settings.overlay) {
      var overlay = $('<div id="modalOverlay"></div>');
      dialog.data('overlay', overlay);
      settings.parent.append(overlay);
    } else {
      $("body").addClass("showOverlay");
    }
    settings.parent.append(dialog);
    dialog.show('fast');
    return dialog;
  };

  $.showError = function (options) {
    var error;
    var settings = $.extend({
      modalClass: 'modalError',
      title: 'Oops!',
      message: 'There has been an error.',
      content: null
    }, options);
    if (settings.content) {
      error = $('<div class="modalErrorText"></div>').append(settings.content);
    } else {
      error = $('<div class="modalErrorText"></div>').text(settings.message);
    }
    if (settings.message2) {
      error = error.add($('<div class="modalErrorText2"></div>').text(settings.message2));
    }
    if (settings.link) {
      error = error.add($('<div class="modalErrorText2"><a href="' + settings.link.url + '">' + settings.link.text + '</a></div>'));
    }
    return $.modal({
      content: error,
      title: {text: settings.title, titleClass: 'modalErrorTitle'},
      buttons: [{title: 'Ok', type: 'close'}],
      id: settings.id,
      modalClass: settings.modalClass
    });
  };

  $.closeModal = function (e, dialog, link) {
    $('.lightbox').each(function () {
      $(this).find('table').floatThead('destroy');
      $(this).scrollTop(0).scrollLeft(0).find('.tableScrollWrapper').scrollTop(0).scrollLeft(0);
      $(this).removeClass('lightbox');
    });
    if (e && !link) {
      e.preventDefault();
    }

    if (!dialog) {
      if (e && e.data && e.data.dialog) {
        dialog = e.data.dialog;
      } else {
        dialog = $('.modal').last();
      }
    }

    if (!dialog) {
      return;
    }
    if (typeof dialog.dialog == "object") {
      dialog = dialog.dialog;
    }

    dialog.trigger("hidden");

    dialog.hide('fast', function () {
      if (!dialog.data('noRemove')) {
        dialog.remove();
      }
    });
    var overlay = dialog.data('overlay');
    if (overlay) {
      overlay.remove();
    } else {
      if ($('.modal:visible').length < 2) {
        $("body").removeClass("showOverlay");
      }
    }
  };

  $(document, document.body).on('click', 'div.modalClose', function (e) {
    $.closeModal(e, $(this).closest('.modal'), $(this).hasClass('modalLink'));
  });

  $(document).on('keydown', function (e) {
    if (!$(document.activeElement).is('input')) {
      switch (e.which) {
        case 27:
          $.closeModal(e);
          break;
      }
    }
  });
})(jQuery);
